<template>
  <FilterOverview :showFilterInitially="false" :title="title" @reload="onReload">
    <template #toolbar-left>
      <div class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <b-button-group size="sm" style="width: 100px">
          <b-button
            v-for="regionFilter in regionFilters"
            :key="regionFilter"
            :id="regionFilter"
            @click="regionFilterClicked"
            :disabled="regionFilterDisabled"
            :variant="regionFilter === regionFilterSelected ? 'primary' : ''"
            class="mr-1"
            style="width: 50%"
          >
            {{ regionFilter }}
          </b-button>
        </b-button-group>

        <b-button size="sm" class="ml-2" variant="danger" v-if="isFilterSupplied" @click="resetAllFilters">
          Alle Filter zurücksetzen
        </b-button>
      </div>
    </template>
    <template #table="{ tableHeight }">
      <AgGridVue
        :style="{ height: tableHeight + 80 + 'px' }"
        class="ag-theme-alpine m-0 p-0"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowModelType="'serverSide'"
        :getRowHeight="getRowHeight"
        :statusBar="statusBar"
        suppressRowTransform
        :tooltipShowDelay="tooltipShowDelay"
        :tooltipMouseTrack="true"
        @grid-ready="onGridReady"
        @rowDoubleClicked="onRowDoubleClicked"
        suppressCellFocus
      ></AgGridVue>
      <AufgabeErstellenModal
        @aufgabeErstellt="onAufgabeErstellt"
        :prefixedVerlinkungen="aufgabenVerlinkung"
      ></AufgabeErstellenModal>
    </template>
  </FilterOverview>
</template>
<script>
import FilterOverview from '@/components/common/filter-overview.vue';
import { differenceInDays, parseISO, add } from 'date-fns';
import CustomTooltip from '@/components/flugverfuegbarkeit/custom-tooltip.vue';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import 'ag-grid-enterprise';
import AufgabeErstellenModal from '@/components/aufgaben/aufgabe-erstellen-modal.vue';
import StatusBarComponent from '@/components/flugverfuegbarkeit/status-bar-ag-grid.vue';
import * as odataService from '@/core/common/services/odata.service';
import CellRendererPax from '@/components/produkte/reisetermine/cell-renderer-pax.vue';
import CellRendererAufgaben from '@/components/produkte/reisetermine/cell-renderer-aufgaben.vue';
import CellRendererPrioritaet from '@/components/flugverfuegbarkeit/cell-renderer-prioritaet.vue';
import CellRendererZimmerkontingente from '@/components/common/zimmerkontingente/cell-renderer-zimmerkontingente.vue';
import { formatDateWithoutHours } from '@/core/common/helpers/utils';
import CustomHeader from '@/components/flugverfuegbarkeit/custom-header.vue';
import AgDateRangePicker from '@/components/flugverfuegbarkeit/ag-date-range-picker.vue';
import { mapState, mapGetters } from 'vuex';
import { fernRegions, euRegions } from '@/core/common/helpers/utils.js';

export default {
  components: {
    FilterOverview,
    AgGridVue,
    CustomTooltip,
    StatusBarComponent,
    CellRendererAufgaben,
    CellRendererPax,
    CellRendererPrioritaet,
    CellRendererZimmerkontingente,
    CustomHeader,
    AgDateRangePicker,
    AufgabeErstellenModal,
  },
  data() {
    return {
      title: 'ZVC-Übersicht',
      isFilterSupplied: false,
      columnDefs: null,
      tooltipShowDelay: 0,
      regionFilters: ['EU', 'Fern'],
      regionFilterSelected: null,
      regionFilterDisabled: true,
      statusBar: null,
      gridApi: null,
      columnApi: null,
      prefixedVerlinkungen: [],
      count: null,
      expand: {
        reise: {},
        pax: {},
        aufgaben: { orderBy: ['faelligkeitsdatum'] },
        metadata: {},
        zimmerkontingente: {},
      },
      defaultColDef: {
        floatingFilter: true,
        filter: true,
        flex: 1,
        resizable: false,
        sortable: false,
        suppressMenu: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        filterParams: {
          defaultToNothingSelected: true,
        },
        tooltipComponent: 'CustomTooltip',
      },
    };
  },
  computed: {
    ...mapState({
      aufgabenVerlinkung: state => state.zvc.aufgabenVerlinkung,
    }),
    ...mapGetters(['getKategorienDestinationYield', 'getReisekuerzelOptions', 'getKategorienLabel']),
    reisenOptions() {
      return this.getReisekuerzelOptions.map(r => r.reisekuerzel);
    },
    // gibt als label den Lesbaren und als value den internen Wert zurück
    getAufgabenKategorienLabels() {
      return this.getKategorienDestinationYield.map(kategorie => ({
        label: this.getKategorienLabel(kategorie),
        value: kategorie,
      }));
    },
    // es wird eine Zuordnung erstellt, die die Labels mit den internen Werten "verbindet"
    aufgabenLabelToValueMap() {
      const map = {};
      this.getAufgabenKategorienLabels.forEach(item => {
        map[item.label] = item.value;
      });
      return map;
    },
  },
  created() {
    this.statusBar = {
      statusPanels: [{ statusPanel: 'StatusBarComponent', key: 'statusBarCompKey', align: 'left' }],
    };
    this.columnDefs = [
      {
        field: 'reise.reisekuerzel',
        headerName: 'Reisekürzel',
        width: 120,
        minWidth: 120,
        headerComponent: 'CustomHeader',
        filter: 'agSetColumnFilter',
        filterParams: {
          refreshValuesOnOpen: true,
          values: params => params.success(this.reisenOptions),
          debounceMs: 1000,
        },
        floatingFilter: true,
        sortable: true,
      },
      {
        field: 'reise.prioritaet',
        headerName: 'Priorität',
        sortable: true,
        width: 120,
        minWidth: 120,
        filterParams: {
          values: [0, 1, 2, 3],
          debounceMs: 1000,
          cellRenderer: 'CellRendererPrioritaet',
        },
        floatingFilter: true,
        cellRenderer: 'CellRendererPrioritaet',
        headerComponent: 'CustomHeader',
      },

      {
        field: 'startdatum',
        headerName: 'Startdatum',
        floatingFilterComponent: 'AgDateRangePicker',
        floatingFilterComponentParams: {
          isChild: true,
        },
        sortable: true,
        filter: 'AgDateRangePicker',
        valueFormatter: params => formatDateWithoutHours(params.value),
        minWidth: 180,
        width: 180,
        floatingFilter: true,
      },

      {
        field: 'garantiert',
        headerName: 'Garantiert',
        width: 120,
        minWidth: 120,
        cellRenderer: params => {
          const { value } = params;
          if (value) {
            return "<i class='fas fa-check text-success'/></span>";
          } else {
            return "<i class='ml-1 fas fa-times text-danger'/>";
          }
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          cellRenderer: params => {
            if (params.value === '(Select All)') {
              return params.value;
            } else if (params.value) {
              return "<i class='fas fa-check text-success'/>";
            } else if (!params.value) {
              return "<i class='ml-1 fas fa-times text-danger'/>";
            }
          },
          debounceMs: 1000,
        },
      },
      {
        field: 'releasedatum',
        headerName: 'Release (TbR)',
        headerTooltip: 'Tage bis Release',
        filter: 'AgDateRangePicker',
        floatingFilter: true,
        floatingFilterComponent: 'AgDateRangePicker',
        floatingFilterComponentParams: {
          isChild: true,
        },
        minWidth: 160,
        width: 160,
        cellRenderer: params => {
          const daysUntilRelease = differenceInDays(parseISO(params.value), new Date());
          return formatDateWithoutHours(params.value) + ' (' + daysUntilRelease + ')';
        },
        headerComponent: 'CustomHeader',
        sortable: true,
      },
      {
        headerName: 'Zimmerkontingente',
        field: 'zimmerkontingente',
        cellRenderer: 'CellRendererZimmerkontingente',
        width: 200,
        minWidth: 200,
        cellRendererParams: { isZvc: true },
        cellStyle: {
          'line-height': 'normal',
          display: 'flex',
          'align-items': 'center',
          'justify-items': 'center',
          overflow: 'visible',
        },
        filter: false,
      },
      {
        field: 'pax',
        headerName: 'PAX',
        cellRenderer: 'CellRendererPax',
        filter: 'agTextColumnFilter',
        filterParams: {
          buttons: ['apply'],
        },
        minWidth: 200,
        width: 200,
        suppressRowTransform: true,
        cellStyle: {
          'line-height': 'normal',
          display: 'flex',
          'justify-items': 'center',
          overflow: 'visible',
        },
      },
      {
        field: 'metadata.erwartetePax',
        headerName: 'Erw. PAX',
        headerTooltip: 'Erwartete PAX',
        filter: false,
        minWidth: 120,
        width: 120,
      },
      {
        field: 'erwartetePaxMinusIstPax',
        headerName: 'Noch erw. PAX',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['> Verf. Zimmerkapazität', '<= Verf. Zimmerkapazität'],
          cellRenderer: params => {
            if (params.value !== null) {
              if (params.value === '> Verf. Zimmerkapazität') {
                return `<span style="font-size: 13px; display:flex; align-items:center">> Verf. Zimmerkapazität <span class="badge badge-danger ml-1" style="width: 16px; height: 16px"> </span></span>`;
              } else if (params.value === '<= Verf. Zimmerkapazität') {
                return `<span style="font-size: 13px; display:flex; align-items:center"><= Verf. Zimmerkapazität <span class="badge badge-success ml-1" style="width: 16px; height: 16px"> </span></span>`;
              } else return '(Select all)';
            }
          },
        },
        minWidth: 170,
        width: 170,
        cellRenderer: params => {
          const { zimmerkontingentePersonenFrei, nochErwartetePax } = params.data;
          const variant = nochErwartetePax <= zimmerkontingentePersonenFrei ? 'success' : 'danger';
          return `<span style="font-size: 1rem" class="badge badge-${variant}
          }">${nochErwartetePax}</span>`;
        },
      },
      {
        field: 'maxPaxMinusIstPax',
        headerName: 'PAX bis Max',
        minWidth: 170,
        width: 170,
        filterParams: {
          values: ['> Verf. Zimmerkapazität'],
          cellRenderer: params => {
            if (params.value !== null) {
              if (params.value === '> Verf. Zimmerkapazität') {
                return `<span style="font-size: 13px; display:flex; align-items:center">> Verf. Zimmerkapazität <span class="badge badge-warning ml-1" style="width: 16px; height: 16px"> </span></span>`;
              } else return '(Select all)';
            }
          },
        },
        cellRenderer: params => {
          const { paxBisMax, zimmerkontingentePersonenFrei } = params.data;
          const variant = paxBisMax > zimmerkontingentePersonenFrei ? 'warning' : 'secondary';
          return `<span style="font-size: 1rem" class="badge badge-${variant}
          }">${paxBisMax}</span>`;
        },
      },
      {
        field: 'aufgaben',
        headerName: 'Aufgaben',
        cellRenderer: 'CellRendererAufgaben',
        filter: 'agSetColumnFilter',
        filterParams: {
          refreshValuesOnOpen: true,
          values: params => {
            const labels = this.getAufgabenKategorienLabels.map(item => item.label);
            params.success(labels);
          },
          debounceMs: 500,
        },
        cellRendererParams: params => ({
          data: params.data,
          filter: 'zvc-overview',
          ansicht: 'zvc-overview',
          onPlusSignClicked: () => {
            this.$bvModal.show('modalAufgabeEdit');
          },
        }),
        cellStyle: {
          'line-height': 'normal',
          display: 'flex',
          'align-items': 'center',
        },
        minWidth: 550,
        width: 550,
      },
      {
        field: 'reise.region',
        headerName: 'Region',
        filter: 'agSetColumnFilter',
        width: 100,
        minWidth: 100,
        filterParams: {
          values: ['EU', 'Fern'],
          debounceMs: 1000,
        },
        cellRenderer: params => {
          if (euRegions.includes(params.value)) {
            return 'EU';
          } else if (fernRegions.includes(params.value)) {
            return 'Fern';
          } else return '-';
        },
        hide: true,
      },
    ];
  },
  methods: {
    getRowHeight(params) {
      const amountZimmerkontingente = params.data?.zimmerkontingente?.filter(
        z => z.isDeleted === false
      ).length;
      const amountZvcAufgaben = params.data?.aufgaben?.filter(aufgabe =>
        this.getKategorienDestinationYield.includes(aufgabe.kategorie)
      ).length;
      const maxZimmerkontingente = Math.max(Math.ceil(amountZimmerkontingente / 4) * 45, 45);
      const maxAufgaben = Math.max(1, amountZvcAufgaben) * 45;
      return params.data ? Math.max(maxZimmerkontingente, maxAufgaben) : 45;
    },
    updateStatusBar(displayedRowsCount) {
      const statusBarComponent = this.gridApi?.getStatusPanel('statusBarCompKey');
      statusBarComponent?.setRowCount(displayedRowsCount);
      statusBarComponent?.setOdataCount(this.count);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      const updateData = () => {
        const server = this.server();
        const datasource = this.createDatasource(server);
        params.api.setServerSideDatasource(datasource);
      };
      updateData();
    },
    createDatasource(server) {
      return {
        getRows: async params => {
          const response = await server.getData(params.request);
          if (response.success) {
            params.success({
              rowData: response.rows,
            });
            this.updateStatusBar(this.gridApi?.getDisplayedRowCount());
          } else {
            params.fail();
          }
        },
      };
    },
    server() {
      return {
        getData: async request => {
          console.log('[Datasource] - rows requested by grid: ', request);
          const response = await odataService.getReisetermin({
            filter: this.getOdataFilterFromAgGridRequest(request),
            top: request.endRow - request.startRow,
            skip: request.startRow,
            count: true,
            expand: this.expand,
            ...(request.sortModel.length > 0
              ? {
                  orderBy: request.sortModel.map(s => s.colId.replaceAll('.', '/') + ' ' + s.sort),
                }
              : { orderBy: 'reise/prioritaet asc, reise/reisekuerzel asc, startdatum asc' }),
          });
          this.count = response.count;
          this.regionFilterDisabled = false;
          return {
            success: true,
            rows: response.data,
          };
        },
      };
    },
    getOdataFilterFromAgGridRequest(request) {
      this.checkIfFilterSupplied();

      const filterKeys = Object.keys(request.filterModel);
      let filters = [];

      const allFilterKeys = filterKeys.map(key => {
        const formattedKey = key.replaceAll('.', '/');
        if (request.filterModel[key].filterType === 'text') {
          if (formattedKey === 'pax') {
            if (request.filterModel[key].filter.includes('-')) {
              const min = parseInt(request.filterModel[key].filter.split('-')[0]);
              const max = parseInt(request.filterModel[key].filter.split('-')[1]);
              return { 'pax/ist': { le: max, ge: min } };
            } else if (request.filterModel[key].filter[0] === '>') {
              const min = parseInt(request.filterModel[key].filter.split('>')[1]);
              return { 'pax/ist': { gt: min } };
            } else if (request.filterModel[key].filter[0] === '<') {
              const max = parseInt(request.filterModel[key].filter.split('<')[1]);
              return { 'pax/ist': { lt: max } };
            } else return { 'pax/ist': parseInt(request.filterModel[key].filter) };
          }
          return { [formattedKey]: { startswith: request.filterModel[key].filter } };
        } else if (request.filterModel[key].filterType === 'set') {
          if (key === 'reise.region') {
            const regionValues = request.filterModel[key].values
              .map(value => (value === 'Fern' ? fernRegions : euRegions))
              .flat();
            return { [formattedKey]: { in: regionValues } };
          }
          if (formattedKey === 'erwartetePaxMinusIstPax') {
            if (request.filterModel[key].values.length === 1) {
              if (request.filterModel[key].values.includes('> Verf. Zimmerkapazität')) {
                return {
                  ['nochErwartetePax']: {
                    gt: { type: 'guid', value: 'zimmerkontingentePersonenFrei' },
                  },
                };
              } else {
                return {
                  ['nochErwartetePax']: {
                    le: { type: 'guid', value: 'zimmerkontingentePersonenFrei' },
                  },
                };
              }
            } else return;
          }
          if (formattedKey === 'maxPaxMinusIstPax') {
            if (request.filterModel[key].values.length === 1) {
              if (request.filterModel[key].values.includes('> Verf. Zimmerkapazität')) {
                return {
                  ['paxBisMax']: {
                    gt: { type: 'guid', value: 'zimmerkontingentePersonenFrei' },
                  },
                };
              }
            }
          }
          if (formattedKey === 'aufgaben') {
            // die lesbaren Werte werden in die internen Werte konvertiert
            const values = request.filterModel[key].values.map(label => this.aufgabenLabelToValueMap[label]);
            // Gib mir alle Aufgaben, bei denen die Kategorie (a/kategorie) gleich (eq) diesem speziellen Wert (value) ist.
            // und wenn eine Zeile noch eine weitere Aufgabe hat
            const categoriesFilter = values.map(value => `aufgabe/kategorie eq '${value}'`).join(' or ');
            // O-Data Abfrage, damit nur die Zeilen mit den richtigen Lables angezeigt werden
            return `aufgaben/any(aufgabe: ${categoriesFilter})`;
          } else {
            return { [formattedKey]: { in: request.filterModel[key].values } };
          }
        } else if (request.filterModel[key].filterType === 'date') {
          const dateFrom = new Date(request.filterModel[key].dateFrom.substring(0, 10));
          const dateTo = add(new Date(request.filterModel[key].dateTo), { hours: 23, minutes: 59 });
          if (formattedKey === 'versandReiseunterlagen') {
            return {
              unterlagenversandDatum: {
                ge: dateFrom,
                le: dateTo,
              },
            };
          } else {
            return {
              [formattedKey]: {
                ge: dateFrom,
                le: dateTo,
              },
            };
          }
        } else if (request.filterModel[key].filterType === 'boolean') {
          return { [formattedKey]: { eq: true } };
        }
      });

      filters = [
        ...filters,
        { state: 'Aufgelegt', isDeleted: false, abgesagt: false, releasedatum: { ge: new Date() } },
        ...allFilterKeys,
      ];
      return filters;
    },

    onRowDoubleClicked($event) {
      const { data, rowIndex } = $event;
      this.selectedRowID = rowIndex;
      const routeData = this.$router.resolve({ path: '/reisetermine/' + data.id });
      window.open(routeData.href, '_blank');
    },
    regionFilterClicked($event) {
      const id = $event.target.id;
      if (this.regionFilterSelected === null || this.regionFilterSelected !== id) {
        this.regionFilterSelected = id;
      } else if (this.regionFilterSelected === id) {
        this.regionFilterSelected = null;
      }

      // apply ag grid filter
      if (this.regionFilterSelected) {
        const regionsmanagerFilter = this.gridApi?.getFilterInstance('reise.region');
        regionsmanagerFilter.setModel({ values: [this.regionFilterSelected] });
        this.gridApi.onFilterChanged();
      } else {
        const regionsmanagerFilter = this.gridApi?.getFilterInstance('reise.region');
        regionsmanagerFilter.setModel({});
        this.gridApi.onFilterChanged();
      }
    },
    resetAllFilters() {
      this.gridApi.setFilterModel(null);
      let filterDate1 = this.gridApi.getFilterInstance('startdatum');
      let filterDate2 = this.gridApi.getFilterInstance('releasedatum');
      filterDate1.onDateRangeFilterChanged(null);
      filterDate2.onDateRangeFilterChanged(null);
      this.regionFilterSelected = null;
    },
    checkIfFilterSupplied() {
      this.isFilterSupplied = this.gridApi && Object.keys(this.gridApi?.getFilterModel()).length > 0;
    },
    onAufgabeErstellt() {
      this.gridApi?.deselectAll();
      this.gridApi.onFilterChanged();
    },
    resetPrefixedVerlinkungen() {
      this.prefixedVerlinkungen = [];
    },
    onReload() {
      this.gridApi.onFilterChanged();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-alpine.css';
:deep(.ag-theme-alpine) {
  --ag-font-family: 'Poppins';
  --ag-font-size: 13px;
  --ag-tab-min-width: 300px;
}
:deep(.ag-theme-alpine .ag-header-cell) {
  font-weight: 500;
  font-size: 14px;
}
:deep(.ag-theme-alpine .ag-header-group-cell) {
  font-weight: 500;
  font-size: 14px;
}
</style>
