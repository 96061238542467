<template>
  <div>
    <ckeditor
      :editor="editor"
      v-model="editorData"
      :config="editorConfig"
      :disabled="isDisabled"
      @input="onInput"
    ></ckeditor>
  </div>
</template>

<script>
function MentionCustomization(editor) {
  editor.conversion.for('upcast').elementToAttribute({
    view: {
      name: 'span',
      key: 'data-mention',
      classes: 'mention',
      attributes: {
        href: true,
        'data-mention-id': true,
      },
    },
    model: {
      key: 'mention',
      value: viewItem => {
        // The mention feature expects that the mention attribute value
        // in the model is a plain object with a set of additional attributes.
        // In order to create a proper object use the toMentionAttribute() helper method:
        const mentionAttribute = editor.plugins.get('Mention').toMentionAttribute(viewItem, {
          // Add any other properties that you need.
          link: viewItem.getAttribute('href'),
          userId: viewItem.getAttribute('data-mention-id'),
        });

        return mentionAttribute;
      },
    },
    converterPriority: 'high',
  });

  // Downcast the model 'mention' text attribute to a view <a> element.
  editor.conversion.for('downcast').attributeToElement({
    model: 'mention',
    view: (modelAttributeValue, { writer }) => {
      // Do not convert empty attributes (lack of value means no mention).
      if (!modelAttributeValue) {
        return;
      }

      return writer.createAttributeElement(
        'span',
        {
          class: 'mention',
          'data-mention-id': modelAttributeValue.userId,
        },
        {
          // Make mention attribute to be wrapped by other attribute elements.
          priority: 20,
          // Prevent merging mentions together.
          id: modelAttributeValue.uid,
        }
      );
    },
    converterPriority: 'high',
  });
}
import CKEditor from '@ckeditor/ckeditor5-vue2';
import {
  Bold,
  ClassicEditor,
  Essentials,
  Italic,
  Paragraph,
  Font,
  Table,
  Mention,
  Underline,
  List,
} from 'ckeditor5';
import 'ckeditor5/dist/ckeditor5.css';
import { mapState } from 'vuex';

export default {
  name: 'CKEditor',
  components: {
    ckeditor: CKEditor.component,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '...',
    },
    disableMentions: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(v) {
      this.editorData = v ?? '';
    },
  },
  computed: {
    ...mapState({ users: state => state.users.users }),
    formattedUsers() {
      return this.users
        .filter(({ givenName }) => givenName !== null && !givenName.toLowerCase().includes('test'))
        .filter(({ surname }) => surname !== null && !surname.toLowerCase().includes('test'))
        .filter(({ mail }) => mail !== null)
        .map(({ givenName, surname, id }) => ({
          userId: id,
          id: '@' + givenName + ' ' + surname,
        }));
    },
  },
  onDestroy() {
    document.querySelector('.ck-editor__editable').ckeditorInstance.destroy();
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: this.value ?? '',
      editorConfig: {
        placeholder: this.placeholder,
        toolbar: {
          items: [
            'undo',
            'redo',
            '|',
            'bold',
            'italic',
            'underline',
            '|',
            'fontColor',
            'insertTable',
            '|',
            'bulletedList',
            'numberedList',
          ],
        },
        plugins: [
          Bold,
          Essentials,
          Italic,
          Underline,
          Paragraph,
          Font,
          Table,
          Mention,
          List,
          MentionCustomization,
        ],

        removePlugins: this.disableMentions ? [Mention] : [],
        mention: {
          dropdownLimit: 4,
          feeds: [
            {
              marker: '@',
              feed: this.getFeedItems,
            },
          ],
        },
      },
    };
  },
  methods: {
    getFeedItems(queryText) {
      return this.formattedUsers.filter(isItemMatching).slice(0, 10);

      function isItemMatching(item) {
        const searchString = queryText.toLowerCase().replaceAll('@', '');
        return item.id.replaceAll('@', '').toLowerCase().startsWith(searchString);
      }
    },
    onInput(data) {
      this.$emit('input', data);
    },
  },
};
</script>

<style>
:root {
  --ck-z-default: 100;
}
.ck-content {
  min-height: 80px;
  max-height: 300px;
  overflow: auto;
}
/* Style all tables within comments */
.comment table {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-collapse: collapse;
}

.comment table td,
.comment table th {
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 8px;
}

/* Optionally, add some styling to the headers */
.comment table th {
  background-color: #f2f2f2;
}
</style>

<style scoped>
:deep(.mention) {
  background-color: #c6d1da;
  padding: 1px 6px;
  border-radius: 8px;
  font-weight: 500;
  margin-right: 4px;
}
</style>
